import Swal from "sweetalert2"
let confirm = (title, text, callback, secondcallback) => {
    Swal.fire({
        title,
        text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#5a8dee",
        cancelButtonColor: "#ff5b5c",
        confirmButtonText: "Yes",
        preConfirm: () => {
            Swal.showLoading(); // Show loading indicator

            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve('Process completed!');
                }, 2000);
            });
        }
    }).then((result) => {
        if (result.isConfirmed) {
            callback();
        }
        else {
            secondcallback()
        }
    })
}

export { confirm };